//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Glide from '@glidejs/glide';
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
// import SfButton from '~/components/StorefrontUi/SfButton.vue';
import ImageWrapper from '~/components/image/ImageWrapper.vue';

export default {
  name: 'SfGallery',
  components: {
    SfImage,
    ImageWrapper,
    // SfButton,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    thumbWidth: {
      type: [Number, String],
      default: null,
    },
    thumbHeight: {
      type: [Number, String],
      default: null,
    },
    thumbsPerView: {
      type: Number,
      default: 4,
    },
    current: {
      type: Number,
      default: 1,
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          type: 'slider',
          autoplay: false,
          rewind: false,
          gap: 0,
        };
      },
    },
    outsideZoom: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: 'img',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    thumbImageTag: {
      type: String,
      default: 'img',
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadedImages: {
        0: true, // always load first image
      },
      loadedThumbImages: {
        0: true, // always load first four images
      },
      positionStatic: {},
      eventHover: {},
      glide: null,
      glideThumbs: null,
      activeIndex: this.current - 1,
      style: '',
      pictureSelected: this.images[0] || {
        alt: '',
        zoom: '',
        big: {
          url: '',
        },
        desktop: '',
        placeholder: '',
      },
      isZoomStarted: false,
      thumbsSliderOptions: {
        type: 'slider',
        autoplay: false,
        rewind: false,
        gap: 0,
        perView: 1,
      },
    };
  },
  computed: {
    mapPictures() {
      // map images to handle picture tags with SfImage
      return this.images.map(({ desktop, big }) => ({
        mobile: desktop,
        desktop: big,
      }));
    },
    updatedSliderOptions() {
      return { ...this.sliderOptions, startAt: this.activeIndex };
    },
    updatedThumbsSliderOptions() {
      return { ...this.thumbsSliderOptions, startAt: 0 };
    },
    definedPicture() {
      const { zoom, big, desktop } = this.pictureSelected;
      const definedPicture = zoom || big || desktop;
      if (definedPicture) {
        definedPicture.alt = this.pictureSelected?.alt;
        definedPicture.placeholder = this.pictureSelected?.placeholder;
      }
      return definedPicture || '';
    },
  },
  watch: {
    images() {
      this.$nextTick(() => {
        if (this.glide && this.glideThumbs) {
          this.glide.update();
          this.glideThumbs.update();
          this.glide.go('=0');
          this.glideThumbs.go('=0');
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length === 0) return;
      const glide = new Glide(this.$refs.glide, this.updatedSliderOptions);
      const glideThumbs = new Glide(this.$refs.glideThumbs, this.updatedThumbsSliderOptions);
      glide.on('run', () => {
        this.handleLoadImage(glide.index);
        this.go(glide.index);
      });
      glideThumbs.on('run', () => {
        this.handleThumbLoadImage(glideThumbs.index);
      });
      glide.mount();
      glideThumbs.mount();
      this.glide = glide;
      this.glideThumbs = glideThumbs;
    });
  },
  beforeDestroy() {
    if (this.glide && this.glideThumbs) {
      this.glide.destroy();
      this.glideThumbs.destroy();
    }
  },
  methods: {
    addBasePath,
    handleLoadImage(index) {
      if (!this.loadedImages[index]) this.loadedImages = { ...this.loadedImages, [index]: true };
    },
    isImageLoaded(index) {
      return this.loadedImages[index];
    },
    handleThumbLoadImage(index) {
      if (!this.loadedThumbImages[index]) this.loadedThumbImages = { ...this.loadedThumbImages, [index]: true };
    },
    isThumbImageLoaded(index) {
      return this.loadedThumbImages[index];
    },
    resetLoadedImages() {
      this.loadedImages = { 0: true };
      this.loadedThumbImages = { 0: true };
    },
    positionObject(index) {
      if (this.$refs.sfGalleryBigImage) {
        if (this.outsideZoom) {
          return this.$refs.glide?.getBoundingClientRect() || '';
        }
        return this.$refs.sfGalleryBigImage[
          index
        ].$el?.getBoundingClientRect() || '';
      }
      return '';
    },
    go(index) {
      this.pictureSelected = this.images[index];
      if (!this.glide) return;
      this.activeIndex = index;
      /**
       * Event for current image change (`v-model`)
       * @type {Event}
       */
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      this.$emit('click', index + 1);
      if (this.glide) {
        this.glide.go(`=${index}`);
      }
    },
    goThumbsPrev() {
      if (!this.glideThumbs) return;
      this.glideThumbs.go('|<');
    },
    goThumbsNext() {
      if (!this.glideThumbs) return;
      this.glideThumbs.go('|>');
    },
    startZoom() {
      if (this.enableZoom) {
        this.isZoomStarted = true;
      }
    },
    moveZoom($event, index) {
      if (this.enableZoom) {
        this.eventHover = $event;
        if (this.outsideZoom) {
          this.positionStatic = this.positionObject(index);
          this.$refs.outSide.style.cssText = `position: absolute; left: ${
            $event.clientX - this.positionStatic.x
          }px; top: ${$event.clientY - this.positionStatic.y}px; z-index: 1;`;
          this.$refs.imgZoom.$el.children[0].style.cssText = 'transform: scale(2); width: 300px; height: auto;';
          this.$refs.imgZoom.$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        } else {
          this.positionStatic = this.positionObject(index);
          this.$refs.sfGalleryBigImage[index].$el.children[0].style.cssText = 'top: 0; transform: scale(2);';
          this.$refs.sfGalleryBigImage[
            index
          ].$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        }
      }
    },
    removeZoom(index) {
      if (this.enableZoom) {
        this.isZoomStarted = false;
        if (this.outsideZoom) return;
        this.$refs.sfGalleryBigImage[index].$el.children[0].style.transform = 'scale(1)';
      }
    },
  },
};
